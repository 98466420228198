import React from "react";
import { Stack, useTheme, useMediaQuery } from "@mui/material";
import styles from "./styles/Landing.module.css";
import ProfileCard from "./ProfileCard";

const profiles = [
  {
    title: 'Mi R3D',
    key: 'mired',
    img: '/seleccionar-mir3d.svg',
    backgroundImage: '/landing/mir3d.png'
  },
  {
    title: 'Fabricante',
    key: 'manufacturer',
    img: '/seleccionar-fabricar.svg',
    backgroundImage: '/landing/fabricante.png'
  }
];

const AboutProfiles = ({
  openProfile,
  onSelectedProfile
}: $TSFixMe) => {
  const theme = useTheme();
  const isXL = useMediaQuery(theme.breakpoints.up('xl'));
  const handleOpenProfile = (selectedProfile: $TSFixMe) => {
    onSelectedProfile(selectedProfile);
  }

  return (
    <div id={'about_profiles'} className={styles.about_profiles_container}>
      <div className={styles.about_profiles_content}>
        <h2>CONECTAMOS LOS PUNTOS</h2>
        <p>
          Conectamos la capacidad de fabricantes con clientes a
          través de proyectos.
        </p>
        <Stack alignItems="center" position="relative" width="100%" gap={2}>
          <Stack
            maxWidth={isXL ? 4000 : 900}
            gap={isXL ? 10 : 5}
            width="100%"
            direction={{ xs: "column", md: "row" }}
            justifyContent={{ xs: "flex-start", md: "center" }}
            alignItems={{ xs: "center", md: "center" }}
          >
            {
              profiles.map((profile) => {
                return (
                  <ProfileCard
                    profile={profile.title}
                    img={profile.img}
                    onClick={handleOpenProfile}
                    key={profile.key}
                    profileKey={profile.key}
                    backgroundImage={profile.backgroundImage}
                    alt={profile.key}
                    currentProfile={openProfile}
                  />
                )
              })
            }
          </Stack>
        </Stack>
        <p>Selecciona un perfil para más información.</p>
      </div>
    </div>
  );
};

export default AboutProfiles;
