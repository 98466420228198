import styles from './styles/home.module.css';

//Componentes
import Landing from '../components/LandingComponent/Landing';
// import Sidebar from '../components/SidebarComponent/Sidebar';
import Navbar from '../components/NavbarComponent/Navbar';
import MenuNavMovil from '../components/NavbarComponent/MenuNavMovil';
import Head from 'next/head';

//Hooks
import { getSession } from 'next-auth/client';
import Footer from '../components/FooterComponent/Footer';

export default function Home({
  session
}: $TSFixMe) {
  return (
    <div className={styles.container}>
      <Head>
        <title>R3D</title>
        <meta property="og:description" content="Una plataforma colaborativa para fabricar productos bajo pedido"></meta>
        <meta name="description" content="Una plataforma colaborativa para fabricar productos bajo pedido"></meta>
        <meta name="keywords" content="ingenieria, manufactura, diseño, mexico, technologia, innovacion, r3d, fabricantes, diseñadores"></meta>
        <meta property="og:url" content={`${process.env.NEXT_PUBLIC_DOMAIN}`}></meta>
        <meta property="og:title" content="R3D"></meta>
        <meta property="og:type" content="website"></meta>
        <meta property="og:image" content="/landing/brand_index_og_image.png"></meta>
        <meta name="twitter:card" content="app"></meta>
      </Head>
      <header className={styles.header}>
        {/*Navbar para dispositivos mayores a 768px de resolucion. */}
        <Navbar session={session} />
        {/*Menu para dispositivos menores a 768px de resolucion. */}
        {/* <Sidebar session={session} /> */}
      </header>
      <MenuNavMovil session={session}/>
      <Landing session={session} />
      <Footer session={session} />
    </div>
  )
}

export async function getServerSideProps({
  req
}: $TSFixMe) {
  const session = await getSession({ req });

  return {
    props: {
      session,
    }
  }
}
