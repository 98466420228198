import React from "react";
import Image from "next/image";
import { Stack } from "@mui/material";
import styles from "./styles/Landing.module.css";

const AboutMakerTools = ({
  title,
  title2,
  description
}: $TSFixMe) => {

  return (
    <div id={"about_tools"} className={styles.about_container}>
      <Stack alignItems="center" position="relative" width="100%">
        <div className={styles.about_tools_content}>
          <h1>
            {title} <br />
            {title2}
          </h1>
          <p>{description}</p>
        </div>
      </Stack>
      <Stack
        position="absolute"
        alignItems="center"
        className={styles.div_arrow_scrolldown}
      >
        <Image
          className={styles.arrow_scrolldown}
          src={"/landing/arrow_scrolldown.png"}
          alt={"arrow_scrolldown"}
          width={30}
          height={15}
          priority={true}
        />
      </Stack>
    </div>
  );
};

export default AboutMakerTools;
