import React, { useState, useEffect } from "react";
import Image from "next/image";
import { Stack } from "@mui/material";
import styles from "./styles/Landing.module.css";

const NUM_VIDEOS = 6;
const NUM_SECS = 3;

const videos = [
  {
    src: "https://r3d-almacenamiento.s3.us-east-2.amazonaws.com/about_r3d/assets/video_1.mov",
    type: "video/mp4",
    poster: "/landing/poster_1.jpg",
  },
  {
    src: "https://r3d-almacenamiento.s3.us-east-2.amazonaws.com/about_r3d/assets/video_2.mov",
    type: "video/mp4",
    poster: "/landing/poster_2.jpg",
  },
  {
    src: "https://r3d-almacenamiento.s3.us-east-2.amazonaws.com/about_r3d/assets/video_3.mp4",
    type: "video/mp4",
    poster: "/landing/poster_3.jpg",
  },
  {
    src: "https://r3d-almacenamiento.s3.us-east-2.amazonaws.com/about_r3d/assets/video_4.mov",
    type: "video/mp4",
    poster: "/landing/poster_4.jpg",
  },
  {
    src: "https://r3d-almacenamiento.s3.us-east-2.amazonaws.com/about_r3d/assets/video_5.mov",
    type: "video/mp4",
    poster: "/landing/poster_5.jpg",
  },
  {
    src: "https://r3d-almacenamiento.s3.us-east-2.amazonaws.com/about_r3d/assets/video_6.mov",
    type: "video/mp4",
    poster: "/landing/poster_6.jpg",
  },
];

const Industry = () => {
  const [indexVideo, setIndexVideo] = useState(0);

  useEffect(() => {
    const timer = setTimeout(() => {
      playNextVideo();
    }, 1000 * NUM_SECS);
    return () => clearTimeout(timer);
  }, []);

  useEffect(() => {
    const timer = setTimeout(() => {
      playNextVideo();
    }, 1000 * NUM_SECS);

    const lastVideo = document.getElementById(`${NUM_VIDEOS-1}_video`);
    if(lastVideo && indexVideo !== NUM_VIDEOS-1){
      // @ts-expect-error TS(2339): Property 'pause' does not exist on type 'HTMLEleme... Remove this comment to see the full error message
      lastVideo.pause(); 
      // @ts-expect-error TS(2339): Property 'currentTime' does not exist on type 'HTM... Remove this comment to see the full error message
      lastVideo.currentTime = 0;
    }

    return () => clearTimeout(timer);
  }, [indexVideo]);

  const playNextVideo = () => {
    let nextIndex = indexVideo;
    if(nextIndex < NUM_VIDEOS-1){
      nextIndex = nextIndex+1;
    }
    else{
      nextIndex = 0;
    }
    
    setIndexVideo(nextIndex);
    const nextVideo = document.getElementById(`${nextIndex}_video`);
    // @ts-expect-error TS(2531): Object is possibly 'null'.
    nextVideo.play();

    for (let i = 0; i < NUM_VIDEOS-1; i++) {
      if(i !== nextIndex){
        const prevVideo = document.getElementById(`${i}_video`);
        if(prevVideo){
          // @ts-expect-error TS(2339): Property 'pause' does not exist on type 'HTMLEleme... Remove this comment to see the full error message
          prevVideo.pause(); 
          // @ts-expect-error TS(2339): Property 'currentTime' does not exist on type 'HTM... Remove this comment to see the full error message
          prevVideo.currentTime = 0;
        }
      }
    }

  }

  return (
    <div id={"about_industry"} className={styles.about_industry_container}>
      <div className={styles.about_industry_bckg}>
        <Stack alignItems="center" position="relative" width="100%">
          <div className={styles.about_industry_content}>
            <div className={styles.logo_white}>
              <Image
                className={styles.img_potential}
                src={"/IMAGOTIPO-blanco.svg"}
                alt="logo-r3d"
                width={200}
                height={35}
                priority={true}
                key={'logo_r3d_industry'}
              />
            </div>
            <h2>R3Diseñamos la industria</h2>
            <p>
              Democratizamos la fabricación de productos para
              impulsar tu negocio.
            </p>
          </div>
        </Stack>
      </div>
      <Stack direction="row" position="relative" justifyContent="center" width="100%">
        {videos.map((video, index) => {
          return (
            <div className={index === indexVideo ? styles.industry_video_active: styles.industry_video} key={index}>
              <video
                id={`${index}_video`}
                poster={video.poster}
                autoPlay={index === 0 && true}
                muted
                key={`${index}_video`}
                className={styles.video_background}
              >
                <source src={video.src} type={video.type}/>
              </video>
            </div>
          );
        })}
      </Stack>
    </div>
  );
};

export default Industry;
