import { Fragment, useEffect, useRef, useState } from "react";

const Messenger = () => {
  const chatBox = useRef(null);
  const [showDialog] = useState(true);
  const [fbLoaded, setFbLoaded] = useState(false);

  useEffect(() => {
    if (chatBox) {
      // @ts-expect-error TS(2531): Object is possibly 'null'.
      chatBox.current.setAttribute('page_id', '101868691539163');
      // @ts-expect-error TS(2531): Object is possibly 'null'.
      chatBox.current.setAttribute('attribution', 'biz_inbox');

      // @ts-expect-error TS(2339): Property 'fbAsyncInit' does not exist on type 'Win... Remove this comment to see the full error message
      window.fbAsyncInit = () => {
        // @ts-expect-error TS(2304): Cannot find name 'FB'.
        // eslint-disable-next-line no-undef
        FB.init({
          xfbml: true,
          version: 'v13.0'
        });

        setFbLoaded(true);
      }

      const initMessengerSdk = async () => {
        /* eslint-disable */
        (function (d, s, id) {
          var js,
            fjs = d.getElementsByTagName(s)[0];
          if (d.getElementById(id)) {
            return;
          }
          js = d.createElement(s);
          js.id = id;
          // @ts-expect-error TS(2339): Property 'src' does not exist on type 'HTMLElement... Remove this comment to see the full error message
          js.src = `https://connect.facebook.net/es_LA/sdk/xfbml.customerchat.js`;
          // @ts-expect-error TS(2531): Object is possibly 'null'.
          fjs.parentNode.insertBefore(js, fjs);
        })(document, 'script', 'facebook-jssdk');
      }

      initMessengerSdk();
    }
  }, [chatBox]);

  useEffect(() => {
    if (fbLoaded) {
      const controlPlugin = () => {
        if (showDialog) {
          // @ts-expect-error TS(2339): Property 'FB' does not exist on type 'Window & typ... Remove this comment to see the full error message
          window.FB.CustomerChat.showDialog();
        } else {
          // @ts-expect-error TS(2339): Property 'FB' does not exist on type 'Window & typ... Remove this comment to see the full error message
          window.FB.CustomerChat.hideDialog();
        }
      }
  
      controlPlugin();
    }
  }, [fbLoaded, showDialog])

  return (
    <Fragment>
      <div ref={chatBox} className='fb-customerchat'></div>
    </Fragment>
  )
}

export default Messenger;
