import React from "react";
import { Stack, useMediaQuery } from "@mui/material";
import { useRouter } from "next/router";
import styles from "./styles/Landing.module.css";
import EmblaHorizontalCarousel from "../EmblaHorizontalCarousel";
import Button from "../ButtonsComponent/Button";

const Potential = ({
  title,
  description,
  images,
  imagesMovil,
  session
}: $TSFixMe) => {
  const router = useRouter();
  const isMovil = useMediaQuery('(max-width: 800px)');

  return (
    <div id={"about_potential"} className={styles.about_container}>
      <div className={styles.about_potential_content}>
        <h2>{title}</h2>
        <p>{description}</p>
        <div className={styles.about_potential_images}>
          <Stack height={700} width='100%' maxWidth={1500}>
            <EmblaHorizontalCarousel images={isMovil ? imagesMovil : images} loop={true} autoplay={true} />
          </Stack>
          {!session && 
            <Stack
                position="absolute"
                bottom={{ xs: 50, sm: isMovil ? 50 : 110, md:120, lg: 110, xl: 60}}
                alignItems="center"
                width="100%"
              >
                <Button
                  name={"CREAR PERFIL"}
                  id={"btn_create_profile_landing"}
                  onClick={() => router.push("/sign_up")}
                />
              </Stack>
            }
          </div>
        </div>
    </div>
  );
};

export default Potential;
