import { useEffect, useRef, useState } from "react";


const AutoTyping = ({
  steps
}: $TSFixMe) => {
  const index = useRef(0);
  const [indexWord, setIndexWord] = useState(0);
  const [currentLetters, setCurrentLetters] = useState('');
  const [currentWord, setCurrentWord] = useState(steps[0].toUpperCase());


  useEffect(() => {
    index.current = 0;
    setCurrentLetters('');
  }, [currentWord]);

  useEffect(() => {
    let timerId2: $TSFixMe;
    const timeoutId = setTimeout(() => {
      setCurrentLetters((value) => value + currentWord.charAt(index.current));
      index.current += 1

      if (currentLetters.length === currentWord.length) {
        timerId2 = setTimeout(() => {
          if (indexWord === steps.length - 1) {
            setIndexWord(0);
            setCurrentWord(steps[0].toUpperCase());
          } else {
            setIndexWord((prevState) => prevState + 1)
            setCurrentWord(steps[indexWord + 1].toUpperCase());
          }
        }, 1000);
      }

    // @ts-expect-error TS(2769): No overload matches this call.
    }, [80]);

    return () => {
      clearTimeout(timeoutId);
      clearTimeout(timerId2);
    }
  }, [currentLetters, currentWord, steps, indexWord])


  return (
    <p>HECHO Y DISEÑADO EN {currentLetters}</p>
  )
}

export default AutoTyping;