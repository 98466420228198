import useEmblaCarousel from 'embla-carousel-react';
import Autoplay from 'embla-carousel-autoplay';
import Image from "next/image";
import styles from './styles/EmblaHorizontalCarousel.module.css';
import { useCallback, useState, useEffect } from "react";

//loop, default = true
//autoplay, default = true
const EmblaHorizontalCarousel = ({
    images,
    loop,
    autoplay,
    hideButtons,
    delay
}: $TSFixMe) => {
    const options = { delay: delay ? delay : 4000 } // Options
    const autoplayRoot = (emblaRoot: $TSFixMe) => emblaRoot.parentElement // Root node
    const autoplayOptions = Autoplay(options, autoplayRoot)

    const [emblaRef, emblaApi] = useEmblaCarousel({ loop: loop === false ? false : true }, autoplay && [autoplayOptions]);
    const [prevBtnEnabled, setPrevBtnEnabled] = useState(false);
    const [nextBtnEnabled, setNextBtnEnabled] = useState(false);

    const scrollNext = useCallback(() => {
        if (emblaApi) emblaApi.scrollNext();
    }, [emblaApi])

    const scrollPrev = useCallback(() => {
        if (emblaApi) emblaApi.scrollPrev()
    }, [emblaApi]);

    const onSelect = useCallback(() => {
        if (!emblaApi) return;
        setPrevBtnEnabled(emblaApi.canScrollPrev());
        setNextBtnEnabled(emblaApi.canScrollNext());
    }, [emblaApi]);

    useEffect(() => {
        if (!emblaApi) return;
        emblaApi.reInit({ loop: loop === false ? false : true }, autoplay && [autoplayOptions]);
        setPrevBtnEnabled(emblaApi.canScrollPrev());
        setNextBtnEnabled(emblaApi.canScrollNext());
        emblaApi.on("select", onSelect);
    }, [emblaApi, onSelect, images]);

    return (
        <div className={styles.embla}>
            {
                !hideButtons &&
                <button onClick={scrollPrev} disabled={!prevBtnEnabled} className={styles.embla_buttonToScroll}>
                    <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" width="24px" fill="#000000">
                        <path d="M0 0h24v24H0V0z" fill="none" opacity=".87" />
                        <path d="M16.62 2.99c-.49-.49-1.28-.49-1.77 0L6.54 11.3c-.39.39-.39 1.02 0 1.41l8.31 8.31c.49.49 1.28.49 1.77 0s.49-1.28 0-1.77L9.38 12l7.25-7.25c.48-.48.48-1.28-.01-1.76z" />
                    </svg>
                </button>
            }
            <div className={styles.viewPort} ref={emblaRef}>
                <div className={styles.embla_container}>
                    {
                        images.map((image: $TSFixMe, index: $TSFixMe) => {
                            return (
                                <div key={index} className={styles.embla_slide}>
                                    <Image src={image.src} blurDataURL={image.blurDataURL} unoptimized={true} layout='fill' objectFit='contain' placeholder='blur' alt='imagen producto' />
                                </div>
                            )
                        })
                    }
                </div>
            </div>
            {
                !hideButtons &&
                <button onClick={scrollNext} disabled={!nextBtnEnabled} className={styles.embla_buttonToScroll}>
                    <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" width="24px" fill="#000000">
                        <path d="M24 24H0V0h24v24z" fill="none" opacity=".87" />
                        <path d="M7.38 21.01c.49.49 1.28.49 1.77 0l8.31-8.31c.39-.39.39-1.02 0-1.41L9.15 2.98c-.49-.49-1.28-.49-1.77 0s-.49 1.28 0 1.77L14.62 12l-7.25 7.25c-.48.48-.48 1.28.01 1.76z" />
                    </svg>
                </button>
            }
        </div>
    );
}

export default EmblaHorizontalCarousel;