import React, { useEffect, useState } from "react";
import { useRouter } from 'next/router';
import Link from "next/link";
import styles from "./styles/Landing.module.css";
import Messenger from "../common/Messenger/Messenger";
import { blurForBackgroundProfileImg } from "../../utils/blur";
import { ProjectStoreProvider } from "../../store";

import AboutR3D from "./AboutR3D";
import AboutProfiles from "./AboutProfiles";
import Tools from "./Tools";
import Potential from "./Potential";
import Industry from "./Industry";
import Project from "./Project";

const imagesMaker = [
  {
    src: "/landing/mi_estudio.png",
    width: 8000,
    height: 3000,
    blurDataURL: blurForBackgroundProfileImg,
  },
  {
    src: "/landing/mi_portafolio.png",
    width: 8000,
    height: 3000,
    blurDataURL: blurForBackgroundProfileImg,
  },
];

const imagesMovilMaker = [
  {
    src: "/landing/mi _estudio_movil.png",
    width: 500,
    height: 800,
    blurDataURL: blurForBackgroundProfileImg,
  },
  {
    src: "/landing/mi_portafolio_movil.png",
    width: 500,
    height: 800,
    blurDataURL: blurForBackgroundProfileImg,
  },
];

const imagesManufacturer = [
  {
    src: "/landing/mi_fabrica.png",
    width: 8000,
    height: 3000,
    blurDataURL: blurForBackgroundProfileImg,
  },
  {
    src: "/landing/mis_productos.png",
    width: 8000,
    height: 3000,
    blurDataURL: blurForBackgroundProfileImg,
  },
];

const imagesMovilManufacturer = [
  {
    src: "/landing/mi_fabrica_movil.png",
    width: 500,
    height: 800,
    blurDataURL: blurForBackgroundProfileImg,
  },
  {
    src: "/landing/mis_productos_movil.png",
    width: 500,
    height: 800,
    blurDataURL: blurForBackgroundProfileImg,
  },
];

const Landing = ({
  session
}: $TSFixMe) => {
  const router = useRouter();
  const [openProfile, setOpenProfile] = useState(null);
  const { section } = router.query;

  useEffect(() => {
    if (section) {
      if(openProfile) setOpenProfile(null);
      const profilesSection = document.querySelector("#about_profiles");
      // @ts-expect-error TS(2531): Object is possibly 'null'.
      profilesSection.scrollIntoView({
        behavior: "smooth",
        block: "end",
        inline: "nearest",
      });
      
      // @ts-expect-error TS(2345): Argument of type 'string | string[]' is not assign... Remove this comment to see the full error message
      setOpenProfile(section);
      openTools();
      router.replace('/', undefined, { shallow: true });
    }
  }, [section]);

  useEffect(() => {
    openTools();
  }, [openProfile]);

  const openTools = () => {
    if (openProfile) {
      const toolsSection = document.querySelector("#about_tools");
      // @ts-expect-error TS(2531): Object is possibly 'null'.
      toolsSection.scrollIntoView({
        behavior: "smooth",
        block: "end",
        inline: "nearest",
      });
    }
  }

  return (
    <>
      <main className={styles.main}>
        <Messenger />
        <section>
          <AboutR3D session={session} />
        </section>
        <div className={styles.background}>
          <section>
            <AboutProfiles
              openProfile={openProfile}
              onSelectedProfile={setOpenProfile}
            />
          </section>
          {openProfile === "maker" && (
            <>
              <section>
                <Tools
                  title={`Una herramienta`}
                  title2={"para crear"}
                  description={
                    "Explora proyectos, conecta con clientes y desarrolla productos para su fabricación."
                  }
                />
              </section>
              <section>
                <Potential
                  title={"LIBERA TU POTENCIAL"}
                  description={
                    "Crea un perfil, comparte tu trabajo y empieza a crear."
                  }
                  images={imagesMaker}
                  imagesMovil={imagesMovilMaker}
                  session={session}
                />
              </section>
              <section>
                <Industry />
              </section>
            </>
          )}
          {openProfile === "manufacturer" && (
            <>
              <section>
                <Tools
                  title={`Una herramienta`}
                  title2={"para fabricar"}
                  description={
                    "Explora proyectos, conecta con clientes, cotiza y fabrica productos."
                  }
                />
              </section>
              <section>
                <Potential
                  title={"DIGITALIZAMOS LA INDUSTRIA"}
                  description={
                    "Regístrate, agrega tu capacidad de producción y empieza a fabricar."
                  }
                  images={imagesManufacturer}
                  imagesMovil={imagesMovilManufacturer}
                  session={session}
                />
              </section>
              <section>
                <Industry />
              </section>
            </>
          )}
          {openProfile === "mired" && (
            <>
              <section>
                <Tools
                  title={`Una plataforma`}
                  title2={"para conectar"}
                  description={
                    "Conecta con los fabricantes que tu proyecto necesita."
                  }
                />
              </section>
              <section>
                <ProjectStoreProvider>
                  <Project session={session}/>
                </ProjectStoreProvider>
              </section>
              <section>
                <Industry />
              </section>
            </>
          )}
        </div>
      </main>
      <div className={styles.footer_backTop}>
        <Link href="/" scroll={true}>
          <a>Ir al Inicio</a>
        </Link>
      </div>
    </>
  );
};

export default Landing;
