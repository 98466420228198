import Image from "next/image";
import { Stack, useMediaQuery } from "@mui/material";
import styles from "./styles/ProfileCard.module.css";
import { useState } from "react";

const ProfileCard = ({
  profile,
  profileKey,
  currentProfile,
  backgroundImage,
  alt,
  img,
  onClick
}: $TSFixMe) => {
  const isMovil = useMediaQuery("(max-width: 900px)");
  const [isSelected, setIsSelected] = useState(false);

  const handleOnClickCard = () => {
    setIsSelected(!isSelected);
    onClick(profileKey);
  };

  return (
    <>
      {isMovil ? (
        <div className={ currentProfile ? currentProfile === profileKey ? styles.profileCard_title_active : styles.profileCard_title_notActive : styles.profileCard_card_title}
          onClick={handleOnClickCard}>
          <Image
            src={img}
            alt={`profile_${profile}`}
            width={40}
            height={40}
            priority={true}
          />
          <span>{profile}</span>
        </div>
      ) : (
        <div className={ currentProfile ? currentProfile === profileKey ? styles.profileCard_active : styles.profileCard_notActive : styles.profileCard}
          onClick={handleOnClickCard}
        >
          <Stack position="relative" height="100%">
            <Image
              src={backgroundImage}
              alt={alt}
              layout="fill"
              objectFit="cover"
              unoptimized={true}
            />
            <Stack
              position="absolute"
              top={"11em"}
              width="100%"
              alignItems="center"
            >
              <div className={styles.profileCard_card_title}>
                <Image
                  src={img}
                  alt={`profile_${profile}`}
                  width={40}
                  height={40}
                  priority={true}
                />
                <span>{profile}</span>
              </div>
            </Stack>
          </Stack>
        </div>
      )}
    </>
  );
};

export default ProfileCard;
