import React, { useState } from "react";
import { ObjectId } from "bson";
import { useRouter } from "next/router";
import { useMutation } from "@apollo/client";
import { signIn } from "next-auth/client";
import { useSnackbar } from "notistack";
import styles from "./styles/Landing.module.css";
import Button from "../ButtonsComponent/Button";
import LoadingFeedback from "../LoadingComponent/LoadingFeedback";
import { UPDATE_USER_PROFILES } from "../../apollo/api/user/mutations";
import { getUserAuthToken } from "../../helpers/getUserAuthToken";
import DialogCard from "../common/DialogCard";
import Snackbar from "../SnackbarComponent/Snackbar";

const Project = ({
  session
}: $TSFixMe) => {
  const router = useRouter();
  const { enqueueSnackbar } = useSnackbar();
  const [openChangeProfile, setOpenChangeProfile] = useState(false);
  const [loadingConfirm, setLoadingConfirm] = useState(false);

  //Mutation to add the MIR3D profile to the user if it doesnt have it
  const [updateUserProfiles, { loading: updateUserProfilesLoading }] = useMutation(
    UPDATE_USER_PROFILES,
    {
      onCompleted: async () => {
        setOpenChangeProfile(false);
        await signIn("update-session", { //Updating the user session with the active profiles
          redirect: false,
          token: await getUserAuthToken()
        });

        localStorage.setItem('profile', 'MIR3D');
        router.push({ pathname: "/my_projects/new_project/[id]" }, { pathname: `/my_projects/new_project/${new ObjectId().toString()}` });
      },
      onError: (error) => {
        enqueueSnackbar("", {
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "right",
          },
          // eslint-disable-next-line react/display-name
          content: () => {
             
            return <Snackbar variant={"error"} message={error.message} />;
          },
        });
      },
    }
  );

  const onStartProject = () => {
    const profile = localStorage.getItem('profile');
    if (session && profile && profile !== 'MIR3D') {
      //Does not have MIR3D profile
      setOpenChangeProfile(true);
    }
    else {
      //Has MIR3D MiR3D profile
      router.push({ pathname: "/my_projects/new_project/[id]" }, { pathname: `/my_projects/new_project/${new ObjectId().toString()}` });
    }
  }

  const handleOnConfirmChangeProfile = () => {
    if (session.user.profiles.includes('MIR3D')) {
      //Changing active profile to MIR3D
      setLoadingConfirm(true);
      localStorage.setItem("profile", 'MIR3D');
      router.push({ pathname: "/my_projects/new_project/[id]" }, { pathname: `/my_projects/new_project/${new ObjectId().toString()}` });
    }
    else {
      //Adding profile MIR3D
      setLoadingConfirm(true);
      updateUserProfiles({
        variables: {
          data: {
            profile: 'MIR3D',
          },
          where: {
            id: session.user.userId,
          },
        },
      });
    }
  }

  return (
    <div id={"about_projects"} className={styles.about_container}>
      <div className={styles.about_projects_content}>
        <h2>EL PODER EN TUS MANOS</h2>
        <Button
          name={"INICIAR PROYECTO"}
          id={"btn_start_project_landing"}
          onClick={onStartProject}
        />
        {openChangeProfile &&
          <DialogCard
            hasCloseBtn={true}
            hasConfirmBtn={true}
            onClose={() => setOpenChangeProfile(false)}
            onConfirm={handleOnConfirmChangeProfile}
            isLoading={loadingConfirm}
          >
            <p className={styles.text_center}>Esta acción requiere activar tu perfil MiR3D.</p>
          </DialogCard>
        }
        {updateUserProfilesLoading &&
          <DialogCard
            hasCloseBtn={false}
            hasConfirmBtn={false}
            isLoading={loadingConfirm}
          >
            <LoadingFeedback />
            <p className={styles.text_center}>Tu perfil MIR3D está siendo creado...</p>
          </DialogCard>
        }
      </div>
    </div>
  );
};

export default Project;
