import React from "react";
import { Stack } from "@mui/material";
import { useRouter } from "next/router";
import styles from "./styles/Landing.module.css";
import AutoTyping from "../common/AutoTyping/AutoTyping";
import Button from "../ButtonsComponent/Button";
import { states } from "../../utils/data/states";

const AboutR3D = ({
  session
}: $TSFixMe) => {
  const router = useRouter();

  return (
    <div id={"about_red"} className={styles.slogan_container}>
      <div className={styles.slogan_container}>
        <video
          className={styles.video_background}
          autoPlay
          loop
          muted
          poster="/placeholder.png"
        >
          <source
            src="https://r3d-almacenamiento.s3.us-east-2.amazonaws.com/about_r3d/r3d_landing_video.mp4"
            type="video/mp4"
          />
        </video>
        <AutoTyping steps={states} />
        <h1>Simplificamos el suministro de productos para tu marca</h1>
        {!session && 
          <Stack
            position="absolute"
            bottom={80}
            alignItems="center"
            width={"20%"}
          >
            <Button
              name={"REGISTRO"}
              id={"btn_register_landing"}
              onClick={() => router.push("/sign_up")}
            />
          </Stack>
        }
      </div>
    </div>
  );
};

export default AboutR3D;
